import './App.css';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  return (
    <div className="App">    
      <Header />
      <main>
        <Outlet />  {/* Nested routes will render here */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
