import React from "react";
import logo from "../swarropalloy/logo//footer-logo.png";
import GoogleMap from "./GoogleMap";
import whatapp from "../swarropalloy/svg/whatsapp.svg";

function Footer() {
  return (
    <>
      <footer id="footer" className="mt-0">
        <div className="container">
          <div className="row py-4 my-3 align-items-center text-center">
            <div className="col-md-6 col-lg-3 mb-lg-0 mt-4">
              <a href="/index" className="logo pe-0 pe-lg-3 ">
                <img
                  alt="Swaroop Alloy Pvt. Ltd."
                  src={logo}
                  className=" img-fluid  ms-4 mb-4"
                  style={{width:'130px',height:"90px"}}
                />
              </a>
              <div className=" align-items-center text-center">
                      <h2 className="mb-0 font " style={{lineHeight: '1px', fontWeight:'800', color:'#0f4aaa',letterSpacing:'1px'}}>SOHAN</h2>
                      <br />
                      <h4 className="display-1 mt-0 font"  style={{letterSpacing:'3px', fontWeight:'400',fontSize:'24px'}} >
                        INDUSTRIES
                      </h4>
                    </div>
              {/* <p className="mb-2 text-justify">
                Swaroop Alloy Pvt. Ltd. is specialized in graded Cast Iron and
                Spheroidal Graphite Iron Castings from year 2007. Having
                experience in quality products along with our high skilled team.
              </p> */}
            </div>
            <div className="col-md-6 col-lg-2 align-items-center text-start m-0">
              <h5 className="text-3 mb-3  font">Quick Links</h5>
              <div className="d-flex justify-content-start">
              <ul className="list list-icons list-icons-sm ">
                <li>
                  <i className="fas fa-angle-right" />
                  <a href="/" className="link-hover-style-1 ms-1 font">
                    {" "}
                    About
                  </a>
                </li>
                <li>
                  <i className="fas fa-angle-right" />
                  <a href="/" className="link-hover-style-1 ms-1 font">
                    {" "}
                    Products
                  </a>
                </li>
                <li>
                  <i className="fas fa-angle-right" />
                  <a
                    href="/Gallary"
                    className="link-hover-style-1 ms-1 font"
                  >
                    {" "}
                    Gallary
                  </a>
                </li>
                {/*										<li><i className="fas fa-angle-right"></i><a href="#" className="link-hover-style-1 ms-1"> Quality</a></li>*/}
                <li>
                  <i className="fas fa-angle-right" />
                  <a href="/" className="link-hover-style-1 ms-1 font">
                    {" "}
                    Career
                  </a>
                </li>
                <li>
                  <i className="fas fa-angle-right" />
                  <a href="/" className="link-hover-style-1 ms-1 font">
                    {" "}
                    Contact
                  </a>
                </li>
                {/* <li>
                  <i className="fas fa-angle-right" />
                  <a
                    href="mgt-9/mgt-9.pdf"
                    target="_blank"
                    className="link-hover-style-1 ms-1 font"
                  >
                    {" "}
                    MGT-9
                  </a>
                </li> */}
              </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mt-5 align-items-center text-start">
              <h5 className="text-3 mb-3 font">CONTACT INFO</h5>
              <ul className="list list-icons list-icons-lg">
                <li className="mb-2">
                  <i className="far fa-building text-color-primary" style={{fontSize:'26px'}} />
                  <p className="m-0 font ">
                  SOHAN INDUSTRIES Plot No.<br />
                   G-40,M.I.D.C. KAGAL FIVE STAR, Kolhapur 416216.<br /> GSTIN: 27AAMPJ8654R1ZN

                  </p>
                </li>
                <li className="mb-2">
                  <i className="fa fa-phone text-color-primary "  />
                  <p className="m-0 font">
                    <a href="tel:+919890383622">+919890383622</a> /{" "}
                    <a href="tel:+919921114376">+919921114376</a> <br/ >
                    <a href="tel:+919890383622">+91770985954</a> /{" "}
                    <a href="tel:+919921114376">+919356768368</a>
                  </p>
                </li>
                <li className="mb-1">
                  <i className="far fa-envelope text-color-primary" />
                  <p className="m-0 ">
                    <a className="font" href="mailto:sohanindustriesg40@gmail.com">
                    sohanindustriesg40@gmail.com
                    </a>
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-4 mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3823.3202786261163!2d74.35121037501676!3d16.610687584150092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc0fc141500d21b%3A0xdf18acf562e100d1!2sKagal%20MIDC%20Rd%2C%20Kagal%205%20Star%20MIDC%20Industrial%20Area%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1728291220570!5m2!1sen!2sin"
                style={{ border: 0, width: "100%", height: 220 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
              
              {/* <GoogleMap /> */}
            </div>
          </div>
        </div>
        <div className="footer-copyright footer-copyright-style-2">
          <div className="footer-copyright footer-copyright-style-2">
            <div className="footer-copyright footer-copyright-style-">
              <div className="container py-2">
                <div className="row py-4">
                  {/* <div className="col-lg-12">
                    <div className="text-white text-end pb-3">
                      Page View: 3681 | Unique Visitors: 1218{" "}
                    </div>
                  </div> */}
                  {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-end">
                    <div className="text-white">
                      <a
                        href="https://validator.w3.org/check?uri=referer"
                        target="_blank"
                      >
                        <img
                          src="https://www.w3.org/Icons/valid-xhtml10"
                          alt="Valid XHTML 1.0!"
                          height={31}
                          width={88}
                        />
                      </a>{" "}
                      | © Copyright |{" "}
                      <a href="index.html" className="text-danger">
                        {" "}
                        Swaroop Alloy Pvt. Ltd.{" "}
                      </a>{" "}
                      | All Rights Reserved
                    </div>
                  </div> */}
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center font">
                    <p>
                    Design and Developed by  &nbsp;
                      <a
                        href="https://comtranse.in/"
                        target="_blank"
                        title="Digital Marketing, Search Engine Optimization & Website Designe in Kolhapur, Ichalkaranji, Kudal, Ratnagiri, Vengurla, Kankavali, Sawantwadi,Malvan"
                        className="agnis-designers text-danger"
                        rel="noreferrer"
                      >
                          Comtranse Technology,
                      </a>{" "}
                     {" "}
                     
                        Kolhapur
                    
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ==========================================  whatsapp ======================== */}
      {/* <div className="agnis-mobile-footer">
        <div className="auto-container">
          <div className="row clearfix centred">
            <div className="col-3 col-sm-3 padding-top-5">
              <div className="icon p-2 ">
                <a href="tel:+918888861261">
                  <img
                    src="sticky-footer-images/phone-call.png"
                    alt="call-icon"
                    className="call-icon"
                  />
                </a>
              </div>
            </div>
            <div className="col-6 col-sm-6 padding-top-5">
              <div
                className="icon pr-1 fs-5 p-2 btn-p"
                style={{ background: "#000" }}
              >
                <a href="contact.html" className="text-white font-14 font">
                  Enquiry Now
                </a>
              </div>
            </div>
            <div className="col-3 col-sm-3">
              <div className="icon p-2 ">
                <a href="https://wa.me/+919422046044" target="_blank">
                  <img
                    src="sticky-footer-images/whatsapp.png"
                    alt="whatsapp-icon"
                    className="whatsapp-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
     <div>
  <a className="whatsapp floatIcon"  href="https://wa.me/9921114376">
    <img src={whatapp} className="my-float" alt="#" style={{marginTop: 30, height: 50,  }} />
  </a>
</div>

      {/* ==========================================  whatsapp ======================== */}
    </>
  );
}

export default Footer;
