import React from "react";
import logo from "../swarropalloy/logo/si-logo.jpg";
// import { Navbar, Nav, Container } from 'react-bootstrap';
// import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';


function Header() {
  return (
    <>
      <header
        id="header"
        data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyStartAt': 54, 'stickySetTop': '-54px', 'stickyChangeLogo': false}"
      >
        <div className="header-body header-body-bottom-border-fixed box-shadow-none border-top-0">
          <div className="container-fluid header-top header-top-small-minheight header-top-simple-border-bottom d-none d-sm-block">
            {/* <div className="container py-2"> */}
            <div className="header-row justify-content-between">
              <div className="header-column col-4 px-0">
                <div className="header-row">
                  <div className="header-nav-top">
                    <ul className="nav nav-pills position-relative">
                      <li className="nav-item d-none d-sm-block">
                        {" "}
                        {/* <span className="d-flex align-items-center font-weight-medium ws-nowrap text-3 ps-0 ms-3">
                          <a
                            href="mailto:sohanindustriesg40@gmail.com"
                            className="text-decoration-none text-color-dark text-color-hover-primary"
                          >
                            <i className="icons icon-envelope font-weight-bold position-relative text-4 top-3 me-1" />
                            sohanindustriesg40@gmail.com
                          </a>
                        </span>{" "} */}
                      </li>
                      {/* <li className="nav-item nav-item-left-border nav-item-left-border-remove nav-item-left-border-sm-show">
                        {" "}
                        <span className="d-flex align-items-center font-weight-medium text-color-dark ws-nowrap text-3">
                          <i className="icons icon-clock font-weight-bold position-relative text-3 top-1 me-2" />{" "}
                          Tue - Sun 9:00am - 6:00pm
                        </span>{" "}
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="header-column justify-content-end col-8 px-0 d-none d-md-flex">
                <div className="header-row">
                  <nav className="header-nav-top">
                    <ul className="header-social-icons social-icons social-icons-clean social-icons-icon-gray social-icons-medium custom-social-icons-divider">
                    <li className="nav-item nav-item-left-border nav-item-left-border-remove nav-item-left-border-sm-show">
                        {" "}
                        <span className="d-flex align-items-center font-weight-medium text-color-dark ws-nowrap text-3 font">
                          <i className="icons icon-clock font-weight-bold position-relative text-3 top-1 me-2 " />{" "}
                          sohanindustriesg40@gmail.com
                        </span>{" "}
                      </li>
                      <li className="nav-item nav-item-left-border nav-item-left-border-remove nav-item-left-border-sm-show">
                        {" "}
                        <span className="d-flex align-items-center font-weight-medium text-color-dark ws-nowrap text-3 font">
                          <i className="icons icon-clock font-weight-bold position-relative text-3 top-1 me-2" />{" "}
                          Tue - Sun 9:00am - 6:00pm
                        </span>{" "}
                      </li>
                      <li className="social-icons-facebook">
                        {" "}
                        <a
                          href="http://www.facebook.com/"
                          rel="noreferrer"
                          target="_blank"
                          title="Facebook"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>{" "}
                      </li>
                      <li className="social-icons-twitter">
                        {" "}
                        <a
                          href="http://www.twitter.com/"
                          rel="noreferrer"
                          target="_blank"
                          title="Twitter"
                        >
                          <i className="fab fa-twitter" />
                        </a>{" "}
                      </li>
                      <li className="social-icons-linkedin">
                        {" "}
                        <a
                          href="http://www.linkedin.com/"
                          rel="noreferrer"
                          target="_blank"
                          title="Linkedin"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>{" "}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="header-container container">
            <div className="header-row">
              <div className="header-column w-100">
                <div className="header-row justify-content-between">
                  <div className="header-logo z-index-2 d-flex col-lg-2 px-0 mt-4">
                    {" "}
                    <a href="/" className="mt-2 mx-2">
                      {" "}
                      <img
                        alt="#"
                        data-sticky-width="100%"
                        data-sticky-height={40}
                        data-sticky-top={84}
                        src={logo}
                        style={{ height: "50px", width:'55px' }}
                      />{" "}
                    </a>{" "}
                    <div className="mt-1">
                      <h2 className=" float-start mb-0 font" style={{lineHeight: '29px', fontWeight:'648', color:'#0f4aaa'}}>SOHAN</h2>
                      <h4 className="display-1 font"  style={{letterSpacing:'2px', fontWeight:'400',fontSize:'20px'}} >
                        INDUSTRIES
                      </h4>
                    </div>
                  </div>
                  <div className="header-nav header-nav-links justify-content-end pe-lg-4 me-lg-3">
                    <div className="header-nav-main header-nav-main-arrows header-nav-main-dropdown-no-borders header-nav-main-effect-3 header-nav-main-sub-effect-1">
                      <nav className="collapse">
                        <ul className="nav nav-pills" id="mainNav">
                         
                           <li to="/">
                            <a href="/ " className="nav-link fw-bold font ">
                              Home
                            </a>
                            </ li>

                            <li to="/">
                            <a href="/" className="nav-link fw-bold font">
                              About </ a>
                              </ li>
                         
                           <li to="/">
                            <a href="/" className="nav-link fw-bold font">
                              Products
                            </a>
                            </ li>
                          <li to="/gallary">
                            <a href="/gallary" className="nav-link fw-bold font">
                              Gallary
                            </a>
                          </li>
                          <li to="/">
                            <a href="/" className="nav-link fw-bold font">
                              Career
                            </a>
                          </li>
                          <li to="/">
                            <a href="/" className="nav-link fw-bold font">
                              Contact
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <ul className="header-extra-info custom-left-border-1 d-none d-xl-block">
                    <li className="d-none d-sm-inline-flex ms-0">
                      <div className="header-extra-info-icon">
                        {" "}
                        <i className="icons icon-phone text-3 text-color-dark position-relative top-3" />{" "}
                      </div>
                      <div className="header-extra-info-text line-height-2">
                        {" "}
                        <span className="text-1 font-weight-semibold text-color-default font">
                          CALL US NOW
                        </span>{" "}
                        <strong className="text-4">
                          <a
                            href="tel:+919921114376"
                            className="text-color-hover-primary text-decoration-none font"
                          >
                           +91 9921114376
                          </a>
                        </strong>{" "}
                      </div>
                    </li>
                  </ul>
                  <button
                    className="btn header-btn-collapse-nav ms-4"
                    data-bs-toggle="collapse"
                    data-bs-target=".header-nav-main nav"
                    style={{background:'#0f4aaa'}}
                  >
                    {" "}
                    <i className="fas fa-bars" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
