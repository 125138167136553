import React, { useState } from "react";
import GallaryImg from '../../swarropalloy/new/product13.jpg';
import GallaryImg1 from '../../swarropalloy/new/productimg15.png';
import GallaryImg2 from '../../swarropalloy/new/product13.jpg';
import GallaryImg3 from '../../swarropalloy/new/product11.jpg';
import GallaryImg4 from '../../swarropalloy/new/product6.jpg';
import GallaryImg5 from '../../swarropalloy/new/product9.jpg';
import GallaryImg6 from '../../swarropalloy/new/productimg4.png';
import GallartImg7 from '../../swarropalloy/new/productimg2.png';
import GallartImg8 from '../../swarropalloy/new/product3.JPEG';
import GallartImg9 from '../../swarropalloy/new/product5.JPEG';
import GallartImg10 from '../../swarropalloy/new/product17.JPEG';
import GallartImg11 from '../../swarropalloy/new/product14.JPEG';
import { Modal } from 'react-bootstrap';
import './Gallary.css'; 

function Gallery() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [GallaryImg, GallaryImg1, GallaryImg2, GallaryImg3, GallaryImg4, GallaryImg5, GallaryImg6, GallartImg7, GallartImg8, GallartImg9, GallartImg10, GallartImg11];


  // Function to handle image click
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <>
      <div role="main" className="main">
         {/*======================== Gallery Header Start ======================= */}
         <section className="page-header page-header-modern page-header-lg  m-0 py-5 about-img">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ marginTop: "220px" }}
            >
              <div className="col-md-8 mb-4 mb-md-0">
                <h1 className="text-white font-weight-bold mb-0 ">Gallary</h1>
              </div>
              <div className="col-md-4 ">
                <ul className="breadcrumb justify-content-md-end text-white text-3-5">
                  <li>
                    <a href="/" className="text-white text-decoration-none">
                      HOME
                    </a>
                  </li>
                  <li className="active">Gallary</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*======================== Gallery Header END ======================= */}
   {/*======================== Gallery Section Start ======================= */}
   <section className="container my-5">
          <div className="row">
            {images.map((image, index) => (
              <div key={index} className="col-md-4 mb-4">
                <div className="gallery-item" onClick={() => handleImageClick(image)}>
                  <img
                    src={image}
                    alt={`Gallery Item ${index + 1}`}
                    className="gallery-image rounded shadow"
                  />
                </div>
              </div>
            ))}
          </div>
        </section>
        {/*======================== Gallery Section End ======================= */}

        {/*======================== Full-Screen Modal for Image View ======================= */}
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg" dialogClassName="fullscreen-modal">
          <Modal.Body className="p-0 d-flex justify-content-center align-items-center">
            <button className="close-button" onClick={handleCloseModal}>✕</button>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Full-screen"
                className="modal-fixed-image"
              />
            )}
          </Modal.Body>
        </Modal>
        {/*======================== Modal End ======================= */}
      </div>
    </>
  );
}

export default Gallery;
